import React, {useState, useEffect} from 'react';
import './App.css';
import axios from 'axios';
import {Auth, Hub} from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/core/Alert';
import { makeStyles } from '@material-ui/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import theme from './theme'
import { spacing } from '@material-ui/system';
import { sha256, sha224 } from 'js-sha256';
  import Divider from '@material-ui/core/Divider';  
function Copyright() {
  
  return (
    <Typography variant="body2" color="textSecondary" align="center" mt={2}>
      {' © '}
      <Link color="inherit" href="https://mywavecard.co.uk/">
        Wave
      </Link>{' '}
      {new Date().getFullYear()}
      {'. '}
      All rights reserved.
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert:{
    margin: theme.spacing(2, 0, 2),
  }
}));

const initialFormState={
  username:'',passcode:'',email:'',vericode:'',formType:'signIn',challenge:'',error:false, errorMessage:'', phone_number:'',code:''
}

const cognitoState={
  cognitoUser:null
}

function App() {
  
  
  const [formState, updateFormState] = useState(initialFormState);
  const [cognitoState, updateCognitoState] = useState(null);
  useEffect(()=>{
    checkUser()
    setAuthListener()
  },[])

  async function setAuthListener() {
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signOut':
          updateFormState(()=>({...formState, formType: 'signIn'}))
            break;
        default:
          break;
      }
    });
  }

  function getParams(location) {
    const searchParams = new URLSearchParams(location.search);
    return {
      redirect_uri: searchParams.get('redirect_uri') || '',
      scope: searchParams.get('scope') || '',
      client_id: searchParams.get('client_id') || '',
      response_type: searchParams.get('response_type') || '',
      state: searchParams.get('state') || '',
    };
  }

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      updateCognitoState(()=>({...formState, cognitoUser: user}))
      updateFormState(()=>({...formState, formType: 'signedIn'}))
    } catch (error) {
 
    }
  }

  function onChange(e){
      e.persist()
      updateFormState(()=>({...formState, [e.target.name]: e.target.value, error: false, errorMessage:''}))
  }

  const {formType, phone_number} = formState

  async function verify() {
    const {cognitoUser} = cognitoState
    const {vericode} = formState
    let challengeResponse = vericode;
    const {username, passcode} = formState
    
    // Send the answer of the custom challenge
    Auth.sendCustomChallengeAnswer(cognitoUser, challengeResponse, {"source": "open-banking-authorization"})
        .then(
          cognitoUser => {

            // Successful verification: Make oauth2/authorize call to get auth code and redirect customer.
            let enc_passcode = sha256(passcode);
            const params = getParams(window.location);

            var bodyFormData = new FormData();
            bodyFormData.append('client_id', params.client_id);
            bodyFormData.append('scope', params.scope);
            bodyFormData.append('resource_owner', username);
            bodyFormData.append('redirect_uri', params.redirect_uri);
            bodyFormData.append('original_uri', window.location);
            bodyFormData.append('dp_state', params.state);
            bodyFormData.append('dp_data', enc_passcode);
            
            const headers = {};

            axios.post('https://openbanking-snd.mywavecard.co.uk/oauth-api/oauth2/authorize' , bodyFormData , { headers })
              .then(response => {
                updateFormState(()=>({...formState, formType: 'consentPage', code: response.data.code}))
              })
              .catch(error => {
                updateFormState(()=>({...formState,error: true, errorMessage: 'Something went wrong, Please try again.'}))
              });
          }
        )
        .catch(
          err => {
            console.log(err)
            updateFormState(()=>({...formState, error: true, errorMessage:'Verification failed. Try to login again.',formType: 'signIn'}))
          }
        );
  }
  async function signIn() {

    const {username, passcode} = formState
    let enc_passcode = sha256(passcode);

    Auth.signIn(username, enc_passcode, {"source": "open-banking-authorization"})
    .then(user => {
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
            updateCognitoState(()=>({...formState, cognitoUser: user}))
            updateFormState(()=>({...formState, formType: 'customChallenge', challenge: user.challengeParam.CUSTOM_CHALLENGE_NAME, phone_number: user.challengeParam.USER_PHONE_NUMBER}))
        }
    })
    .catch(err => {
      console.log('ERROR: ', err)
      updateFormState(()=>({...formState,error: true, errorMessage: 'Login failed; Invalid user ID or password.'}))
    });
        
  } 

  function confirmConsent() {
    const {code} = formState
    const params = getParams(window.location);
    const redirectUrl = params.redirect_uri + "?code="+ code +"&state="+ params.state 
    window.location.replace(redirectUrl);
  }

  function cancelConsent() {
    const params = getParams(window.location);
    window.location.replace(params.redirect_uri);
  }
  
  const classes = useStyles();
  const {error, errorMessage} = formState
  const [dense, setDense] = React.useState(false);
  return (
    <div className="App">
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
              <Typography
              component="h1"
              variant="h4"
              color="primary"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Wave<span className="jss9">.</span>
            </Typography>
      {
        formType === 'signIn' &&(
            <div>
            <Typography color="primary" mt={3} component="h1" variant="h6">
            Sharing information with a third party
            </Typography>
            {/* <Typography variant="caption" display="block" gutterBottom>
              SHARE : STEP 1 OF 2 
            </Typography>
            <Divider /> */}
            <Typography mt={2} variant="body2" gutterBottom>
            We need to confirm your identity. Your log on information won't be shared. 
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Enter email or mobile number"
                name="username"
                autoFocus
                onChange={onChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="passcode"
                label="Enter your passcode"
                type="password"
                id="passcode"
                onChange={onChange}
            />
            {
              error &&
                <Alert 
                    className={classes.alert}
                    variant="filled" 
                    severity="error">
                      {errorMessage}
                </Alert>
            }
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={signIn}
            >
                Continue
            </Button>
            </form>
         </div>
        )
      }
      {
        formType === 'customChallenge' &&(
          <div>
            <Typography color="primary" mt={3} component="h1" variant="h6">
            Verify your identity
            </Typography>
            {/* <Typography variant="caption" display="block" gutterBottom>
              SHARE : STEP 1 OF 2 
            </Typography>
            <Divider /> */}
            <Typography mt={2} variant="body2" gutterBottom>
            Enter the authorisation code we sent to your mobile  number ending in {formState.phone_number}
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="vericode"
                label="vericode"
                name="vericode"
                autoFocus
                onChange={onChange}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={verify}
            >
                Verify
            </Button>
            </form>
        </div>
        )
      }
      {
        formType === 'consentPage' &&(
          <div>
            <Typography color="primary" mt={3} component="h1" variant="h6">
            Check what you will be sharing
            </Typography>
            {/* <Typography variant="caption" display="block" gutterBottom>
              SHARE : STEP 1 OF 2 
            </Typography>
            <Divider /> */}
            <Typography mt={2} variant="body2" gutterBottom>
            The information you're asking us to share may include:
            </Typography>
            <div>
            <List dense={dense}>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Account name" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Card number" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Card balance" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Transaction details" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Fees and Charges" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Benefits and rewards" /></ListItem>
                <ListItem><ListItemIcon><FiberManualRecordIcon className="jss9"/></ListItemIcon><ListItemText primary="Your account balance" /></ListItem>
            </List>
            </div>
            <Typography mt={2} mb={2} variant="body2" gutterBottom>
              This information will be shared for 90 days.
            </Typography>
            <Divider className="jss9"/>
            <Typography mt={2} variant="body2" gutterBottom>
              You can stop sharing your data by telling us or you can let your permission expire after 90 days. 
              If you stop sharing data, any data received may be kept by the Third party.
            </Typography>
            <form className={classes.form} noValidate autoComplete="off">
            <Typography mt={4}>
              <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={confirmConsent}
              >
                  Confirm
              </Button>
              </Typography>
              <Typography mt={2}>
              <Button
                  
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={cancelConsent}
              >
                  Cancel
              </Button>
              </Typography>
            </form>
        </div>
        )
      }
      {
        formType === 'signedIn' &&(
          <div>
              Welcome {cognitoState.cognitoUser.username}
              <div>
                <button onClick={
                  ()=> Auth.signOut()
                }>Sign Out</button>
              </div>
          </div>
        )
      }
       </div>
      {/* <div><AuthorizationIframe iframeurl="https://cflsnd.auth.eu-west-1.amazoncognito.com/oauth2/authorize?client_id=426p2k0bq5v89089ueeaoh2asp&response_type=code&scope=aws.cognito.signin.user.admin+email+https://cmll3q0h8e.execute-api.eu-west-1.amazonaws.com/snd/accounts/read+openid+phone+profile&redirect_uri=http://localhost:3000" /></div> */}
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
    </div>
    
  );
}

export default App;
